
let wtframe : HTMLIFrameElement = document.getElementById("wtframe") as HTMLIFrameElement;
function resizeWTFrame() {
        wtframe.height = (window.innerHeight * 0.75).toString();
}
resizeWTFrame();
window.addEventListener('DOMContentLoaded', (event) => {
        resizeWTFrame();
    });
window.addEventListener('load', (event) => {
        resizeWTFrame();
});
window.onresize = () => {
        resizeWTFrame();
};
window.onloadeddata
function isElementInViewport(el) {
        var rect = el.getBoundingClientRect();
        return (
          rect.top >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        );
      }

let animElements = document.querySelectorAll("[animated='true']");


let scrollEv = window.requestAnimationFrame || function(callback){ window.setTimeout(callback, 1000/60)};
function loopEv() {
        for(let i = 0; i<animElements.length; i++)
        {        
                if(isElementInViewport(animElements[i]))
                {
                        animElements[i].setAttribute("anim","fadeIn");
                }else{
                        animElements[i].setAttribute("anim","fadeOut");
                }
        }
        scrollEv(loopEv);
}
loopEv();